




















































































































































































































































































































































































































































































































































































































































































































import Vue from 'vue';
import FormSwitch from '@/components/form/form-switch.vue';
import { CASE_STATUS } from '@/constant/constants';
import popUpModal from '@/components/form/pop-up-modal.vue';
import errorMessage from '@/components/form/error-message.vue';

export default Vue.extend({
  name: 'agent-info',
  components: {
    FormSwitch,
    popUpModal,
    errorMessage,
  },
  watch: {
    changeData: {
      handler(val) {
        if (this.componentLoaded) this.firstPageDataChange();
      },
      deep: true,
      immediate: true,
    },
    totalSales: 'calculateSales',
    inLandSalesPercentage(value: number) {
      this.ausLandSalesPercentage = 100 - value;
      if (this.ausLandSalesPercentage > 100) { this.ausLandSalesPercentage = 100; this.inLandSalesPercentage = 0; }
      if (this.ausLandSalesPercentage < 0) { this.ausLandSalesPercentage = 0; this.inLandSalesPercentage = 100; }
      this.calculateSales();
    },
    ausLandSalesPercentage(value: number) {
      this.inLandSalesPercentage = 100 - value;
      if (this.inLandSalesPercentage > 100) { this.inLandSalesPercentage = 100; this.ausLandSalesPercentage = 0; }
      if (this.inLandSalesPercentage < 0) { this.inLandSalesPercentage = 0; this.ausLandSalesPercentage = 100; }
      this.calculateSales();
    },
    isGreaterThanLimit(value: boolean): boolean {
      this.isAlertActive = value;
      return value;
    },
    packageHeight(value: number) {
      if (value > 100) {
        this.packageHeight = 100;
      } else {
        this.packageHeight = value;
      }
    },
  },
  computed: {
    isPercentageSelected(): boolean {
      return (this.inLandSalesPercentage + this.ausLandSalesPercentage) === 0;
    },
    isScopeSelected(): boolean {
      return this.scope === '';
    },
    isTotalSalesSelected(): boolean {
      return this.totalSales === 0;
    },
    changeData(): any {
      return [this.scope,
        this.inLandSales,
        this.ausLandSales,
        this.moreThanFiveExhibitions,
        this.assesment.operatingMode.BART_TEXT].join();
    },
    getTransport(): boolean[] {
      if (this.transportIndependent) {
        return [false, false, this.transportIndependent];
      }
      if (this.exclusivelyTransport) {
        return [this.exclusivelyTransport, this.subsidiaryCoverage, false];
      }

      return [this.exclusivelyTransport, this.subsidiaryCoverage, this.transportIndependent];
    },
    isDisableAll(): boolean {
      if (this.isAPIObserver) {
        return true;
      }
      const result =
        this.caseStatus === CASE_STATUS.CONTRACT ||
        this.caseStatus === CASE_STATUS.SUGGESTION ||
        this.caseStatus === CASE_STATUS.CONTRACT_NO_SIGNATURE || this.AMSAPISendStatus !== null ||
        this.caseStatus === CASE_STATUS.OFFER_REQUEST_SEND;

      if (result) {
        this.disableAllInputs();
      }
      return result;
    },
  },
  props: ['assesment', 'insuranceData', 'caseStatus', 'isAPIObserver', 'AMSAPISendStatus'],
  data() {
    return {
      totalSalesSelected: true,
      scopeSelected: true,
      percentageSelected: true,
      isAlertActive: false,
      totalSales: 0,
      inLandSales: 0,
      ausLandSales: 0,
      inLandSalesPercentage: 0,
      ausLandSalesPercentage: 0,
      isGreaterThanLimit: false,
      isContainerLoading: true,
      exclusivelyTransport: true,
      subsidiaryCoverage: true,
      transportIndependent: false,
      scope: '',
      moreThanFiveExhibitions: false,
      isImport: false,
      importDescription: '',
      isExport: false,
      exportDescription: '',
      innerPackaging: '',
      outerPackaging: '',
      packageWithoutAdvertising: '',
      whoPacks: '',
      additionalSafeGuard: '',
      additionalSafeGuardDescription: '',
      packageTracing: '',
      packageTracingDescription: '',
      corrosionPackageUsed: '',
      packageTransportTracing: '',
      packageTransportTracingDescription: '',
      additionalValue: '',
      otherCost: '',
      duty: '',
      imaginaryProfit: '',
      packageHeight: '',
      taxesExpenses: '',
      usualTransport: 0,
      transportRelated: 0,
      motorVehicle: 0,
      cepServices: 0,
      areContainerCariedOut: '',
      isFullLoad: '',
      collectiveContainer: '',
      longerThan60Days: false,
      howManyDays: '',
      plannedStorage: '',
      plannedStorageDescription: '',
      isDifferentDeductible: false,
      hasSimilarInsurance: false,
      similarInsuranceDescription: '',
      hasTransportDamageOccured: '',
      transportDamageOccuredDescription: '',
      hasRejectBefore: false,
      ...this.insuranceData,
      modalOpen: false,
      modalTitle: '',
      modalBody: '',
      componentLoaded: false,
      specialProperties: '',
      differentDeductible: '',
    };
  },
  methods: {
    valadations(): boolean {
      if ((this.inLandSalesPercentage + this.ausLandSalesPercentage) === 0) {
        this.percentageSelected = false;
      } else {
        this.percentageSelected = true;
      }
      if (this.scope === '') {
        this.scopeSelected = false;
      } else {
        this.scopeSelected = true;
      }
      if (this.totalSales === 0) {
        this.totalSalesSelected = false;
      } else {
        this.totalSalesSelected = true;
      }
      return this.percentageSelected && this.scopeSelected && this.totalSalesSelected;
    },
    firstPageDataChange() {
      this.$emit(
        'first-page-data-changed',
      );
    },
    openModal(title: string, body: string) {
      this.modalTitle = title;
      this.modalBody = body;
      this.modalOpen = !this.modalOpen;
    },
    modalClose() {
      this.modalTitle = '';
      this.modalBody = '';
      this.modalOpen = false;
    },
    calculateSales() {
      const inLandSales = this.inLandSalesPercentage * this.totalSales;
      const ausLandSales = this.ausLandSalesPercentage * this.totalSales;
      this.inLandSales = inLandSales / 100 || 0;
      this.ausLandSales = ausLandSales / 100 || 0;

      if (this.inLandSalesPercentage > 100) {
        this.inLandSalesPercentage = 100;
      }
      if (this.ausLandSalesPercentage > 100) {
        this.ausLandSales = 100;
      }
      this.isGreaterThanLimit = this.totalSales > 5000000;
    },
    onExclusivelyTransportChange() {
      if (this.exclusivelyTransport) {
        this.transportIndependent = false;
      }
    },
    onTransportIndependentChange() {
      if (this.transportIndependent) {
        this.exclusivelyTransport = false;
        this.subsidiaryCoverage = false;
      } else {
        this.exclusivelyTransport = true;
        this.subsidiaryCoverage = true;
      }
    },
    disableAllInputs() {
      const inputs = document.querySelectorAll('input');
      for (let i = 0; i < inputs.length; i += 1) {
        inputs[i].disabled = true;
      }

      const selects = document.querySelectorAll('select');
      for (let i = 0; i < selects.length; i += 1) {
        selects[i].disabled = true;
      }
    },
    onSubmit() {
      if (!this.valadations()) {
        return;
      }
      const priceData = {
        Geltungsbereich: this.scope,
        UmsatzDE: this.inLandSales,
        UmsatzAusland: this.ausLandSales,
        Ausstellungen: this.moreThanFiveExhibitions,
        BetriebsartWare: this.assesment.operatingMode.BART_TEXT,
      };
      const data = {
        scope: this.scope,
        totalSales: this.totalSales,
        inLandSales: this.inLandSales,
        ausLandSales: this.ausLandSales,
        moreThanFiveExhibitions: this.moreThanFiveExhibitions,
        inLandSalesPercentage: this.inLandSalesPercentage,
        ausLandSalesPercentage: this.ausLandSalesPercentage,
        isGreaterThanLimit: this.isGreaterThanLimit,
        isContainerLoading: this.isContainerLoading,
        exclusivelyTransport: this.exclusivelyTransport,
        subsidiaryCoverage: this.subsidiaryCoverage,
        transportIndependent: this.transportIndependent,
        isImport: this.isImport,
        importDescription: this.importDescription,
        isExport: this.isExport,
        exportDescription: this.exportDescription,
        innerPackaging: this.innerPackaging,
        outerPackaging: this.outerPackaging,
        packageWithoutAdvertising: this.packageWithoutAdvertising,
        whoPacks: this.whoPacks,
        additionalSafeGuard: this.additionalSafeGuard,
        additionalSafeGuardDescription: this.additionalSafeGuardDescription,
        packageTracing: this.packageTracing,
        packageTracingDescription: this.packageTracingDescription,
        corrosionPackageUsed: this.corrosionPackageUsed,
        packageTransportTracing: this.packageTransportTracing,
        packageTransportTracingDescription: this.packageTransportTracingDescription,
        additionalValue: this.additionalValue,
        otherCost: this.otherCost,
        duty: this.duty,
        imaginaryProfit: this.imaginaryProfit,
        packageHeight: this.packageHeight,
        taxesExpenses: this.taxesExpenses,
        usualTransport: this.usualTransport,
        transportRelated: this.transportRelated,
        motorVehicle: this.motorVehicle,
        cepServices: this.cepServices,
        areContainerCariedOut: this.areContainerCariedOut,
        isFullLoad: this.isFullLoad,
        collectiveContainer: this.collectiveContainer,
        longerThan60Days: this.longerThan60Days,
        howManyDays: this.howManyDays,
        plannedStorage: this.plannedStorage,
        plannedStorageDescription: this.plannedStorageDescription,
        isDifferentDeductible: this.isDifferentDeductible,
        hasSimilarInsurance: this.hasSimilarInsurance,
        similarInsuranceDescription: this.similarInsuranceDescription,
        hasTransportDamageOccured: this.hasTransportDamageOccured,
        transportDamageOccuredDescription: this.transportDamageOccuredDescription,
        hasRejectBefore: this.hasRejectBefore,
        specialProperties: this.specialProperties,
        differentDeductible: this.differentDeductible,
      };
      this.$emit('data-changed', '11f434a6-67f5-4761-b6a2-c5ab76f77ae5', priceData, data);
    },
    setValues(insuranceData: any) {
      this.totalSales = insuranceData.totalSales ?? 0;
      // this.totalSales = insuranceData.inLandSales + insuranceData.ausLandSales;
      this.scope = insuranceData.scope ?? '';
      this.inLandSales = insuranceData.inLandSales ?? 0;
      this.ausLandSales = insuranceData.ausLandSales ?? 0;
      this.moreThanFiveExhibitions = insuranceData.moreThanFiveExhibitions ?? false;
      // this.assesment.operatingMode.BART_TEXT = insuranceData.BART_TEXT;
      this.inLandSalesPercentage = insuranceData.inLandSalesPercentage ?? 0;
      this.ausLandSalesPercentage = insuranceData.ausLandSalesPercentage ?? 0;
      this.isGreaterThanLimit = insuranceData.isGreaterThanLimit ?? false;
      this.isContainerLoading = insuranceData.isContainerLoading ?? true;
      this.exclusivelyTransport = insuranceData.exclusivelyTransport ?? true;
      this.subsidiaryCoverage = insuranceData.subsidiaryCoverage ?? true;
      this.transportIndependent = insuranceData.transportIndependent ?? false;
      this.isImport = insuranceData.isImport ?? false;
      this.importDescription = insuranceData.importDescription ?? '';
      this.isExport = insuranceData.isExport ?? false;
      this.exportDescription = insuranceData.exportDescription ?? '';
      this.innerPackaging = insuranceData.innerPackaging ?? '';
      this.outerPackaging = insuranceData.outerPackaging ?? '';
      this.packageWithoutAdvertising = insuranceData.packageWithoutAdvertising ?? '';
      this.whoPacks = insuranceData.whoPacks ?? '';
      this.additionalSafeGuard = insuranceData.additionalSafeGuard ?? '';
      this.additionalSafeGuardDescription = insuranceData.additionalSafeGuardDescription ?? '';
      this.packageTracing = insuranceData.packageTracing ?? '';
      this.packageTracingDescription = insuranceData.packageTracingDescription ?? '';
      this.corrosionPackageUsed = insuranceData.corrosionPackageUsed ?? '';
      this.packageTransportTracing = insuranceData.packageTransportTracing ?? '';
      this.packageTransportTracingDescription = insuranceData.packageTransportTracingDescription ?? '';
      this.additionalValue = insuranceData.additionalValue ?? '';
      this.otherCost = insuranceData.otherCost ?? '';
      this.duty = insuranceData.duty ?? '';
      this.imaginaryProfit = insuranceData.imaginaryProfit ?? '';
      this.packageHeight = insuranceData.packageHeight ?? '';
      this.taxesExpenses = insuranceData.taxesExpenses ?? '';
      this.usualTransport = insuranceData.usualTransport ?? 0;
      this.transportRelated = insuranceData.transportRelated ?? 0;
      this.motorVehicle = insuranceData.motorVehicle ?? 0;
      this.cepServices = insuranceData.cepServices ?? 0;
      this.areContainerCariedOut = insuranceData.areContainerCariedOut ?? '';
      this.isFullLoad = insuranceData.isFullLoad ?? '';
      this.collectiveContainer = insuranceData.collectiveContainer ?? '';
      this.longerThan60Days = insuranceData.longerThan60Days ?? false;
      this.howManyDays = insuranceData.howManyDays ?? '';
      this.plannedStorage = insuranceData.plannedStorage ?? '';
      this.plannedStorageDescription = insuranceData.plannedStorageDescription ?? '';
      this.isDifferentDeductible = insuranceData.isDifferentDeductible ?? false;
      this.hasSimilarInsurance = insuranceData.hasSimilarInsurance ?? false;
      this.similarInsuranceDescription = insuranceData.similarInsuranceDescription ?? '';
      this.hasTransportDamageOccured = insuranceData.hasTransportDamageOccured ?? '';
      this.transportDamageOccuredDescription = insuranceData.transportDamageOccuredDescription ?? '';
      this.hasRejectBefore = insuranceData.hasRejectBefore ?? false;
    },
  },
  mounted() {
    if (!this.assesment) {
      this.$router.replace('/transport-insurance');
    }
    if (this.$attrs.formData) {
      if (this.$attrs.formData.insuranceData) {
        this.setValues(this.$attrs.formData.insuranceData);
      }
    } else if (this.insuranceData) {
      this.setValues(this.insuranceData);
    }

    if (this.isDisableAll) {
      this.disableAllInputs();
    }
  },
  beforeUpdate() {
    this.componentLoaded = true;
  },
});
